<template>
  <div class="view-plans auth">
    <nav class="container">
      <div class="d-flex justify-content-between align-items-center mb-4 pt-4">
        <img src="@/assets/logo.png" />
        <div>
          <button class="btn btn-outline-light" @click="$user.logout">
            Sair
          </button>
        </div>
      </div>
    </nav>

    <div class="container">
      <div class="row">
        <div class="col-md-7 col-lg-5 mx-auto">
          <div class="card">
            <div class="card-body justify-content-center align-items-center d-flex">
              <loading-spinner v-if="loading" />
              <div class="row" v-else>
                <div class="col text-center">
                  <div class="step">Passo 2 de 3</div>

                  <h5>Conheça o plano {{ name }}</h5>
                  <p class="small">{{ description }}</p>
                  <p class="small">Fizemos uma lista de razões porque você não pode deixar de assinar. Olha só:</p>

                  <div class="box">
                    Teste grátis por {{ trialPeriodDays }} dias.
                    <i class="icon-confirm"></i>
                  </div>
                  <div class="box">
                    Acesso a todo o conteúdo disponível.
                    <i class="icon-confirm"></i>
                  </div>
                  <div class="box">
                    Quantidade ilimitada de visualizações por conteúdo.
                    <i class="icon-confirm"></i>
                  </div>
                  <div class="box">
                    Cancele quando quiser.
                    <i class="icon-confirm"></i>
                  </div>

                  <h1 class="mb-4" v-html="formattedPrice" />

                  <button class="btn btn-block btn-lg btn-success" @click="$router.push({ name: 'payment' })">
                    Assinar agora!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('plans', [
      'name',
      'description',
      'price',
      'discount',
      'coupon',
      'items',
      'trialPeriodDays',
      'paymentMethods'
    ]),
    formattedPrice () {
      const [int, cents] = this.price.toFixed(2).split('.')
      return `<sub>R$</sub>${int}<sup>,${cents}</sup>`
    }
  },
  mounted () {
    const planref = window.localStorage.getItem('planref')
    this.loadPlans(planref)
  },
  methods: {
    loadPlans (planref = null) {
      this.loading = true
      this.$store
        .dispatch('plans/get', planref)
        .then(data => {
          if (data.status === 'fail') {
            this.$message.error(data.message)
            window.localStorage.removeItem('planref')
            this.loadPlans()
          } else {
            window.localStorage.setItem('planref', data.coupon)
            setTimeout(() => {
              this.loading = false
            }, 100)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-plans {
  h1 {
    ::v-deep sub {
      font-size: 14px;
      margin-right: 4px;
    }

    ::v-deep sup {
      font-size: 14px;
      top: -1.3em;
      margin-left: 4px;
    }
  }

  .box {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 16px 48px 16px 20px;
    margin: 16px 0;
    display: block;
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #A7D039;
      font-size: 24px;
    }
  }
}
</style>
